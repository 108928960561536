"use client";

import { Menu } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState } from "react";

import { navigationItems } from "../shared/lib";
import { Button } from "./button";
import SocialIcons from "./social-icons/social-icons";

export default function GlassmorphNavbar() {
  const [isOpen, setIsOpen] = useState(false);

  const pathname = usePathname();

  return (
    <nav
      className={`fixed left-1/2 top-0 z-50 mt-7 flex w-11/12 max-w-7xl -translate-x-1/2 flex-col items-center rounded-full bg-background/20 p-3 backdrop-blur-lg md:rounded-full ${
        isOpen ? "rounded-sm" : "rounded-full"
      }`}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <Link
            href="/"
            className="rounded-full border border-transparent hover:border-primary/40"
          >
            <Image
              src="/images/orcdev.png"
              alt="Orc Dev"
              width={40}
              height={40}
            />
          </Link>

          <div className="hidden gap-4 md:flex">
            {navigationItems.map((item) => (
              <Link
                key={item.href}
                href={item.href}
                className={`rounded-md p-1 hover:bg-primary/20 ${
                  pathname === item.href ? "bg-primary/20" : ""
                }`}
              >
                {item.title}
              </Link>
            ))}
          </div>
        </div>

        <div className="hidden md:block">
          <SocialIcons />
        </div>

        <div className="md:hidden">
          <Button onClick={() => setIsOpen(!isOpen)}>
            <Menu className="size-4" />
          </Button>
        </div>
      </div>

      {isOpen && (
        <div className="flex flex-col items-center justify-center gap-3 px-5 py-3 md:hidden">
          {navigationItems.map((item) => (
            <Link
              key={item.href}
              href={item.href}
              className={`rounded-md p-1 hover:bg-primary/20 ${
                pathname === item.href ? "bg-primary/20" : ""
              }`}
              onClick={() => setIsOpen(false)}
            >
              {item.title}
            </Link>
          ))}
        </div>
      )}
    </nav>
  );
}
