export const navigationItems = [

  {
    title: "Components",
    href: "/components",
    items: [],
  },
  {
    title: "Traffic Light",
    href: "/traffic-light",
    items: [],
  },
  {
    title: "Snake Game",
    href: "/snake-game",
    items: [],
  },
  {
    title: "Memory Game",
    href: "/memory-game",
    items: [],
  },
];
